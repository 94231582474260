import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate, useLocation } from '@reach/router';
import axios from 'axios';
import moment from 'moment';
import { Grid, makeStyles, Button, CircularProgress } from '@material-ui/core';
import TagsNavigator from './components/tagsNavigator';
import LayoutLoading from '../layout/layoutLoading';
import QuoteContainer from './quoteContainer';

import { getWithRemote, getWithRemoteApi } from '../../actions/dynamicActions';
import { postSaveQuotation } from '../../actions/serviceActions';
import { showError } from '../../actions/notificationActions';
import { useQuote } from '../../hooks/useQuote';
import { isValidEmail } from '../../utils';
import Utils from '../../utils/utils';

import settings from '../../settings';
import { AUTOS } from '../../utils/risksTypes';

import testData from './testData.json';

const useStyles = makeStyles(() => ({
    appContainer: {
        paddingLeft: 8,
        paddingRight: 8,
        width: '100%'
    },
    hide: {
        display: 'none'
    },
    loader: {
        textAlign: 'center',
        width: '100%'
    }
}));

const DynamicForm = ({ data, insurance, formBuilderId, riskType, saved, calculationType }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const quote = useQuote(riskType);

    const [index, setIndex] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const [fillingExtraFields, setFillingExtraFields] = useState(false);
    const [extraFields, setExtraFields] = useState([]);
    const [extraFieldsOuterIndex, setExtraFieldsOuterIndex] = useState(0);
    const [extraFieldsInnerIndex, setExtraFieldsInnerIndex] = useState(0);
    const [extraFieldsGroupIndex, setExtraFieldsGroupIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const dynamicReducer = useSelector(state => state.dynamicReducer);
    const location = useLocation();

    const steps = [];
    const { quotation: { quotationScreenValues } } = quote;

    let extraFieldsRetrieved = '';
    let multipleFieldsTemplate = [];

    data.groups.forEach((group, i) => {
        if (!group.multiple) {
            group.fields.forEach(field => {
                if (group.groupName === 'lead') {
                    steps.push({
                        ...field,
                        isLead: true
                    });
                } else {
                    steps.push(field);
                }
            });
        } else {
            console.log(group);
            if (group.min > 0) {
                steps.push({
                    value: group.min,
                    type: 'multiple',
                    groupIndex: multipleFieldsTemplate.length
                });
            }
            group.groupIndex = multipleFieldsTemplate.length;
            multipleFieldsTemplate = multipleFieldsTemplate.concat(group.fields.map(f => ({ groupName: group.groupName, ...f })));
        }
    });

    // Este codigo reconstruye la data que se necesita en sessionStorage
    // cuando se ingresa a traves del link
    if (steps.length > 0 && saved && !data.extraFields) {
        const allIsNull = steps.reduce((total, step) => step.value === null);

        if (allIsNull && quotationScreenValues && !Utils.isEmptyObject(quotationScreenValues)) {
            steps.map(step => {
                const property = step.name;
                step.value = quotationScreenValues[property];
            });

            const items = Array.isArray(quotationScreenValues.items) ? [...quotationScreenValues.items] : [];

            extraFieldsRetrieved = items.map((item) => {
                const _item = Object.assign({}, item);
                const templates = [...multipleFieldsTemplate];

                const result = templates.map((group) => {
                    return group.map((temp) => {
                        const _temp = Object.assign({}, temp);
                        const property = _temp.name;
                        if (_temp.groupName === _item.groupName) {
                            _temp.value = _item[property];
                        }
                        return _temp;
                    });
                });

                return result;
            });
            console.log('Extra fields retrieved', extraFieldsRetrieved);
            console.log('DynamicForm data: ', data);

            data.extraFields = extraFieldsRetrieved;
            sessionStorage.setItem(`quotation::${riskType}`, JSON.stringify(data));
            setExtraFields(data.extraFields);
            setIndex(steps.length - 1);
        }
    }

    useEffect(() => {
        if (saved) {
            const lastTag = steps[steps.length - 1];
            if (lastTag.controlType !== 'chip') {
                setInputValue(lastTag.value);
            }
            if (data.extraFields) {
                setExtraFields(data.extraFields);
            }
            setIndex(steps.length - 1);
        } else {
            let initialExtraFields = [];
            data.groups.filter(g => g.multiple).forEach(_ => {
                initialExtraFields.push([]);
            });
            setExtraFields(initialExtraFields);
        }
    }, [saved]);

    const extraFieldsValid = (groups) => {
        return extraFields.filter((_, i) => groups[i].min > 0).every((eF, i) => eF.length >= groups[i].min);
    }

    const isValid = () => {
        let conditions = steps.filter(step => !step.isLead && step.type !== 'multiple').every(step => step.value !== null);

        if (steps.find(step => step.isLead)) {
            conditions = conditions && isValidEmail(inputValue);
        }

        const multipleGroups = data.groups.filter(g => g.multiple);
        console.log(multipleGroups);
        const isRequired = multipleGroups.some(group => group.min > 0);
        if (multipleGroups && isRequired) {
            conditions = conditions && extraFieldsValid(multipleGroups);
        }
        return conditions;
    };

    const handleCloseTag = async (tag) => {
        const tagIndex = steps.findIndex(s => s.name === tag.name);
        const multiGroupIndex = steps.findIndex(s => s.type === 'multiple');
        console.log(tagIndex);
        console.log(steps);
        steps.map(step => {
            const _step = step;
            if (tagIndex <= _step.order) {
                if (_step.name === 'plate') {
                    _step.value = '';
                } else {
                    _step.value = null;
                }
                if (_step.remote) {
                    _step.options = [];
                }
            }

            return _step;
        });

        if (steps[tagIndex].remote) {
            const dependencies = steps[tagIndex].dependency.map(dependency => {
                const dependencyField = steps.find(step => step.name === dependency);

                return dependencyField.value;
            }).join('/');
            const remote = `${typeof steps[tagIndex].remote === 'string' ? steps[tagIndex].remote : steps[tagIndex].remote?.url}${dependencies}`;

            await dispatch(getWithRemote(remote));
        }
        // steps[currenttagIndex].options = dynamicReducer.data;

        if (tag.controlType !== 'chip') {
            setInputValue(tag.value);
        }
        setIndex(tagIndex);
        if (tagIndex < multiGroupIndex) {
            setExtraFieldsInnerIndex(0);
            const updatedExtraFieldGroup = extraFields.map((curr, i) => {
                if (i === steps[multiGroupIndex].groupIndex) {
                    return [];
                }

                return curr;
            })
            setExtraFields(updatedExtraFieldGroup);
        }
    };

    const handleCheck = async (value, extraField) => {
        if (!extraField) {
            if (steps[index].remote && steps[index].remote.includes('plate')) {
                if (value !== '') {
                    try {
                        const response = await axios.get(`${settings.quotationUrlApi}${steps[index].remote}/${value}`);
                        const data = response.data.data;
                        const mappedSteps = steps.map(step => {
                            const _step = step;
                            if (Object.keys(data).find(d => d === step.name) !== undefined) {
                                _step.value = data[step.name];
                            };

                            return _step;
                        });

                        steps[index].value = value;
                        setInputValue('');
                        setIndex(index + (Object.keys(data).length + 1));
                    } catch (error) {
                        dispatch(showError(error.response.data.data));
                    }
                } else {
                    steps[index].value = '';
                    setInputValue('');
                    setIndex(index + 1);
                }
            } else {
                steps[index].value = value;
                setInputValue('');
                setIndex(index + 1);
            }
        } else {
            const currentExtraFieldGroup = extraFields[extraFieldsGroupIndex];
            const currentField = currentExtraFieldGroup[extraFieldsOuterIndex];
            const currentTag = currentField[extraFieldsInnerIndex];
            currentTag.value = value;
            const updatedExtraFieldGroup = currentExtraFieldGroup.map((eF, i) => {
                if (i === extraFieldsOuterIndex) {
                    const tags = eF.map((t, i) => {
                        if (i === extraFieldsInnerIndex) {
                            return currentTag;
                        }
                        return t;
                    });

                    return tags;
                }

                return eF;
            });

            setInputValue('');
            if (currentField.length > extraFieldsInnerIndex + 1) {
                setExtraFieldsInnerIndex(extraFieldsInnerIndex + 1);
            } else {
                setFillingExtraFields(false);
                setExtraFieldsInnerIndex(0);
                if (steps[index].type === 'multiple' && (updatedExtraFieldGroup.length >= steps[index].value)) {
                    setIndex(index + 1);
                }
            }

            const updatedExtraFields = extraFields.map((ex, i) => {
                if (i === extraFieldsGroupIndex) {
                    return updatedExtraFieldGroup
                }

                return ex;
            });
            setExtraFields(updatedExtraFields);
        }
    };

    const handleSelect = async (value, extraField) => {
        console.log('Sent value', value);
        if (!extraField) {
            const currentTag = steps.find((step, i) => i === index);
            if (steps[index + 1] && steps[index + 1].remote) {
                const dependencies = steps[index + 1].dependency.map(dependency => {
                    const dependencyField = steps.find(step => step.name === dependency);

                    return dependencyField.value === null ? value : dependencyField.value;
                }).join('/');
                const remote = `${typeof steps[index + 1].remote === 'string' ? steps[index + 1].remote : steps[index + 1].remote?.url}${dependencies}`;

                await dispatch(getWithRemote(remote));
                if (steps[index].remote) {
                    steps[index].options = dynamicReducer.data;
                }
            }

            if (steps[index + 1] && !steps[index + 1].remote && steps[index].remote) {
                steps[index].options = dynamicReducer.data;
            }

            if (currentTag.controlType === 'chip') {
                steps[index].value = value;
                setIndex(index + 1);
            } else {
                setInputValue(value);
            }
        } else {
            const currentExtraFieldGroup = extraFields[extraFieldsGroupIndex];
            console.log('Current group', currentExtraFieldGroup);
            const currentField = currentExtraFieldGroup[extraFieldsOuterIndex];
            console.log('Current field', currentField);
            const currentTag = currentField[extraFieldsInnerIndex];
            const nextTag = currentField[extraFieldsInnerIndex + 1];
            console.log(currentTag);
            if (nextTag && nextTag.remote) {
                const dependencies = nextTag.dependency.map(dependency => {
                    const allFields = steps.slice().concat(...currentExtraFieldGroup);
                    const dependencyField = allFields.find(step => step.name === dependency);

                    return dependencyField.value === null ? value : dependencyField.value;
                }).join('/');
                const remote = `${typeof nextTag.remote === 'string' ? nextTag.remote : nextTag.remote?.url}${dependencies}`;

                const result = await getWithRemoteApi(remote);
                if (nextTag.remote) {
                    nextTag.options = result.data ? result.data : result;
                }
            }

            if (currentTag.controlType === 'chip') {
                currentTag.value = value;
                const updatedExtraFieldGroup = currentExtraFieldGroup.map((eF, i) => {
                    if (i === extraFieldsOuterIndex) {
                        const tags = eF.map((t, i) => {
                            if (i === extraFieldsInnerIndex) {
                                return currentTag;
                            }
                            return t;
                        });

                        return tags;
                    }

                    return eF;
                });

                if (currentField.length > extraFieldsInnerIndex + 1) {
                    setExtraFieldsInnerIndex(extraFieldsInnerIndex + 1);
                } else {
                    setFillingExtraFields(false);
                    setExtraFieldsInnerIndex(0);
                    if (steps[index].type === 'multiple' && (updatedExtraFieldGroup.length >= steps[index].value)) {
                        setIndex(index + 1);
                    }
                }

                const updatedExtraFields = extraFields.map((ex, i) => {
                    if (i === extraFieldsGroupIndex) {
                        return updatedExtraFieldGroup
                    }

                    return ex;
                });

                setExtraFields(updatedExtraFields);
            } else {
                setInputValue(value);
            }
        }
    };

    const getFormattedValue = (field) => {
        switch (field.dataType) {
            case 'bool':
            case 'object':
            case 'string': return field.value;
            case 'numeric':
                let value = field.value;
                if (isNaN(value)) {
                    value = value.includes(',') ? value.replaceAll(',', '') : value
                }
                return Number(value);
            case 'date': case 'birthdate': return field.controlType.includes('birthdate') ? moment().diff(field.value, 'years') : field.value;
        };
    };

    const buildQuoteBody = () => {
        const quoteBody = {};

        steps.filter(step => !step.isLead && step.type !== 'multiple').forEach(step => {
            if (step.remote) {
                const selectedOption = step.options.find(opt => opt._id === step.value);
                quoteBody[step.name] = selectedOption !== undefined ? selectedOption.name : step.value;
            } else {
                quoteBody[step.name] = getFormattedValue(step);
                if (step.controlType.includes('birthdate')) {
                    quoteBody[`${step.name}_birthdate`] = getFormattedValue({ ...step, dataType: 'date', controlType: 'date' })
                }
            }
        });

        quoteBody.riskType = data.riskType;
        quoteBody.insuranceId = insurance;
        quoteBody.quotas = [3, 6, 9];

        if (typeof quoteBody.ano === 'boolean' && riskType.toUpperCase() === AUTOS) {
            quoteBody.producto = quoteBody.ano === true ? 'Todo Riesgo' : 'Perdida Total';
        }

        quoteBody.items = extraFields.map((g) => {
            console.log(g);
            const mappedFields = g.map((f, i) => {
                const obj = {};
                f.forEach(_f => {
                    if (_f.remote && _f.dataType !== 'numeric') {
                        const selectedOption = _f.options.find(opt => opt._id === _f.value);
                        obj[_f.name] = selectedOption !== undefined ? selectedOption.name : _f.value;
                    } else {
                        obj[_f.name] = getFormattedValue(_f);
                        if (_f.controlType.includes('birthdate')) {
                            obj[`${_f.name}_birthdate`] = getFormattedValue({ ..._f, dataType: 'date', controlType: 'date' });
                        }
                    }
                    obj.groupName = _f.groupName;
                });

                obj.qItem = extraFields.reduce((prev, curr) => prev += curr.length, 0);

                if (obj.vehicleType) {
                    obj.vehicleType = obj.vehicleType.includes('Heavy') ? 'HeavyTruck' : 'LightTruck';
                }

                return obj;
            });

            return mappedFields;
        }).reduce((prev, curr) => prev = prev.concat(...curr), [])

        const leadField = steps.find(step => step.isLead);

        quoteBody.lead = inputValue;

        quoteBody.calculationType = calculationType;

        return {
            lead: leadField !== undefined ? { email: inputValue } : null,
            item: quoteBody
        };
    };

    const buildLibraQuoteBody = () => {
        const quoteBody = {};
        const innerQuoteBody = {}

        steps.filter(step => !step.isLead && step.type !== 'multiple').forEach(step => {
            if (step.remote) {
                const selectedOption = step.options.find(opt => opt._id === step.value);
                innerQuoteBody[step.name] = selectedOption !== undefined ? selectedOption.name : step.value;
            } else {
                innerQuoteBody[step.name] = getFormattedValue(step);
                if (step.controlType.includes('birthdate')) {
                    innerQuoteBody[`${step.name}_birthdate`] = getFormattedValue({ ...step, dataType: 'date', controlType: 'date' })
                }
            }
        });

        quoteBody.riskType = data.riskType;
        quoteBody.insuranceId = insurance;
        quoteBody.quotas = [3, 6, 9];

        if (typeof quoteBody.ano === 'boolean' && riskType.toUpperCase() === AUTOS) {
            quoteBody.producto = quoteBody.ano === true ? 'Todo Riesgo' : 'Perdida Total';
        }

        quoteBody.items = extraFields.map((g) => {
            console.log(g);
            const mappedFields = g.map((f, i) => {
                const obj = {};
                f.forEach(_f => {
                    if (_f.remote && _f.dataType !== 'numeric') {
                        const selectedOption = _f.options.find(opt => opt._id === _f.value);
                        obj[_f.name] = selectedOption !== undefined ? selectedOption.name : _f.value;
                    } else {
                        obj[_f.name] = getFormattedValue(_f);
                        if (_f.controlType.includes('birthdate')) {
                            obj[`${_f.name}_birthdate`] = getFormattedValue({ ..._f, dataType: 'date', controlType: 'date' });
                        }
                    }
                    obj.groupName = _f.groupName;
                });

                obj.qItem = extraFields.reduce((prev, curr) => prev += curr.length, 0);

                if (obj.vehicleType) {
                    obj.vehicleType = obj.vehicleType.includes('Heavy') ? 'HeavyTruck' : 'LightTruck';
                }

                return obj;
            });

            return mappedFields;
        }).reduce((prev, curr) => prev = prev.concat(...curr), [])

        const leadField = steps.find(step => step.isLead);

        quoteBody.lead = inputValue;

        quoteBody.calculationType = calculationType;

        const quoteData = testData;
        quoteData.RiesgosPorRamo = [];
        quoteData.RiesgosPorRamo = quoteData.RiesgosPorRamo.concat({
            ...innerQuoteBody,
            AnioVehiculo: 2022,
            Marca: 315,
            Modelo: 21353,
            Uso: 1,
            Origen: 'nacional',
            ClausulaAjuste: 1,
            Accesorios: [ 1 ]
        });
        quoteBody.quoteData = quoteData;

        return {
            lead: leadField !== undefined ? { email: inputValue } : null,
            item: quoteBody
        };
    };

    const saveQuotation = () => {
        const _data = data;
        _data.groups = data.groups.map(group => {
            const _group = group;
            if (!_group.multiple) {
                _group.fields.map(field => {
                    const _field = field;

                    _field.value = steps.filter(step => step.type !== 'multiple').find(step => step._id === _field._id).value;

                    return _field;
                });
            }
            return _group;
        });

        _data.extraFields = extraFields;
        sessionStorage.setItem(`quotation::${riskType}`, JSON.stringify(_data));
    };

    const proceed = async () => {
        setLoading(true);
        saveQuotation();
        const quoteBody = calculationType.includes('libra') ? buildLibraQuoteBody() : buildQuoteBody();
        console.log(quoteBody);
        const resultQuote = await dispatch(postSaveQuotation(quoteBody, riskType));
        sessionStorage.setItem(`quotation::quote::${riskType}`, JSON.stringify({ plan: {}, quotation: resultQuote }));
        localStorage.setItem('quotation::id', resultQuote._id);
        setLoading(false);
        if (location.pathname.includes('embed')) {
            navigate(`/embed/quote/${riskType.toLowerCase()}/${data._id}/${insurance}/${formBuilderId}/${resultQuote._id}/proposal`);
        } else {
            navigate(`/quote/${riskType.toLowerCase()}/${data._id}/${insurance}/${formBuilderId}/${resultQuote._id}/proposal`);
        }
    };

    const handleFillExtraFields = (index) => {
        console.log('index', index);
        if (fillingExtraFields) {
            const updatedExtraFieldGroup = extraFields.map((curr, i) => {
                if (i === extraFieldsGroupIndex) {
                    return curr.slice(0, -1);
                }

                return curr;
            });
            setExtraFields(updatedExtraFieldGroup);
            setFillingExtraFields(false);
        } else {
            const group = data.groups.filter(gr => gr.multiple);
            const fields = group[index].fields.map(f => ({
                ...f,
                groupName: group[index].groupName,
                value: null
            }));
            const updatedExtraFieldGroup = extraFields.map((curr, i) => {
                if (i === index) {
                    return curr.concat([fields]);
                }

                return curr;
            })
            console.log(updatedExtraFieldGroup);
            setInputValue('');
            setExtraFields(updatedExtraFieldGroup);
            setExtraFieldsGroupIndex(index);
            setExtraFieldsOuterIndex(extraFields[index].length);
            setFillingExtraFields(true);
        }
    };

    const handleDeleteExtraField = (index, groupIndex) => {
        const currentGroup = extraFields[groupIndex];
        if (fillingExtraFields) {
            setExtraFieldsInnerIndex(0);
            const updatedFields = currentGroup.map((f, i) => {
                if (i === index) {
                    return f.map((_f) => {
                        return {
                            ..._f,
                            value: null
                        };
                    });
                }

                return f;
            });
            const updatedExtraFieldGroup = extraFields.map((curr, i) => {
                if (i === groupIndex) {
                    return updatedFields;
                }

                return curr;
            })
            setExtraFields(updatedExtraFieldGroup);
        } else {
            const updatedExtraFieldGroup = extraFields.map((curr, i) => {
                if (i === groupIndex) {
                    return curr.filter((c, j) => j !== index);
                }

                return curr;
            })
            setExtraFields(updatedExtraFieldGroup);
        }
    };

    const handleDeleteExtraFieldTag = (index, groupIndex, innerIndex) => {
        console.log('Outerindex', index);
        const updatedExtraFieldsGroup = extraFields.map((curr, i) => {
            if (i === groupIndex) {
                return curr.map((group, j) => {
                    if (j === index) {
                        return group.map((field, k) => {
                            const _field = field;

                            if (k > (innerIndex + 1)) {
                                if (_field.remote) {
                                    _field.options = _field.options.some(opt => opt.main) ? _field.options.filter(opt => opt.main) : [];
                                }
                            }

                            if (k >= (innerIndex + 1)) {
                                _field.value = null;
                            }

                            return _field;
                        });
                    };

                    return group;
                })
            }

            return curr;
        });
        console.log(updatedExtraFieldsGroup)
        setExtraFields(updatedExtraFieldsGroup);
        setExtraFieldsGroupIndex(groupIndex);
        setExtraFieldsOuterIndex(index);
        setExtraFieldsInnerIndex(innerIndex);
        setFillingExtraFields(true);
    };

    const currentGroup = extraFields[extraFieldsGroupIndex];
    console.log('Index', index);
    console.log('Step', steps[index]);
    if (dynamicReducer.isFetching) return <LayoutLoading />;
    return (
        <Grid container justify="center" className={classes.appContainer}>
            <Grid item xs={12} sm={9} md={7} className="cardAppContainer">
                <TagsNavigator
                    classes={classes}
                    index={index}
                    groups={data.groups}
                    handleCloseTag={handleCloseTag}
                    handleFillExtraFields={handleFillExtraFields}
                    fillingExtraFields={fillingExtraFields}
                    extraFields={extraFields}
                    handleDeleteExtraField={handleDeleteExtraField}
                    handleDeleteExtraFieldTag={handleDeleteExtraFieldTag}
                    currentGroupIndex={extraFieldsGroupIndex} />
                {(steps[index] && steps[index].type !== 'multiple') &&
                    <>
                        {!fillingExtraFields && (
                            <QuoteContainer
                                handleSelect={handleSelect}
                                handleCheck={handleCheck}
                                name={steps[index].name}
                                label={steps[index].label}
                                dataType={steps[index].dataType}
                                controlType={steps[index].controlType}
                                options={steps[index].options}
                                data={dynamicReducer.data}
                                isLast={!steps[index + 1]}
                                value={inputValue}
                                validations={steps[index].validations}
                                mask={steps[index].mask}
                                isSearchable={steps[index].isSearchable}
                                quotationScreenOptions={data.options}
                            />
                        )}
                    </>
                }

                {fillingExtraFields && (
                    <QuoteContainer
                        handleSelect={(value) => handleSelect(value, true)}
                        handleCheck={(value) => handleCheck(value, true)}
                        name={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].name}
                        label={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].label}
                        dataType={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].dataType}
                        controlType={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].controlType}
                        options={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].options}
                        data={dynamicReducer.data}
                        value={inputValue}
                        validations={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].validations}
                        mask={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].mask}
                        isSearchable={currentGroup[extraFieldsOuterIndex][extraFieldsInnerIndex].isSearchable}
                        quotationScreenOptions={data.options}
                    />)}
                {!fillingExtraFields ? (
                    <Grid container style={{ justifyContent: 'center' }} className="contentForm">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={proceed}
                            disabled={!isValid()}
                        >
                            COTIZAR
                        </Button>
                    </Grid>
                ) : (
                    <Grid container style={{ justifyContent: 'flex-end' }} className="contentForm">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleFillExtraFields}
                        >
                            Cancelar
                        </Button>
                    </Grid>
                )}
                {loading
                    ? <div className={classes.loader}>
                        <CircularProgress color="primary" />
                    </div> : ''}
            </Grid>
        </Grid>
    );
};

export default DynamicForm;
