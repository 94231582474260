// const quotationUrlBase = 'http://localhost:8088/';
// const agentUrlApiBase = 'http://localhost:8886/';

const quotationUrlBase =
  (window._env_ ? window._env_.REACT_APP_COTIZADOR_API_URL : false) ||
  process.env.REACT_APP_COTIZADOR_API_URL;

const authUrlBase =
    (window._env_ ? window._env_.REACT_APP_AUTH_API_URL : false) ||
    process.env.REACT_APP_AUTH_API_URL;
const quotationUrlApi = `${quotationUrlBase}v1/`;
const quotationUrlV2Api = `${quotationUrlBase}v2/`;

const siseUrlBase =
    (window._env_ ? window._env_.REACT_APP_SISE : false) ||
    process.env.REACT_APP_SISE;
const siseUrlApi = `${siseUrlBase}api/`;

const vinculationFormID =
    (window._env_ ? window._env_.REACT_APP_VINCULATION_FORM_ID : false) ||
    process.env.REACT_APP_VINCULATION_FORM_ID;

const recognitionAPPUrlBase =
    (window._env_ ? window._env_.REACT_APP_RECOGNITIONAPP : false) ||
    process.env.REACT_APP_RECOGNITIONAPP;

const agentUrlApiBase =
    (window._env_ ? window._env_.REACT_APP_AGENT_API_URL : false) ||
    process.env.REACT_APP_AGENT_API_URL;

const agentUrlApi = `${agentUrlApiBase}v1/`;
const agentUrlPublicApi = `${agentUrlApiBase}`;

const siseBlacklist =
    (window._env_ ? window._env_.REACT_APP_SISE_BLACKLIST : false) ||
    process.env.REACT_APP_SISE_BLACKLIST;

const siseReservado =
    (window._env_ ? window._env_.REACT_APP_SISE_RESERVADO2G : false) ||
    process.env.REACT_APP_SISE_RESERVADO2G;

const siseGenerateLicenseNumber = (window._env_ ? window._env_.REACT_APP_SISE_GENERATE_LICENSE_NUMBER : false) ||
process.env.REACT_APP_SISE_GENERATE_LICENSE_NUMBER;

const brokerName =  (window._env_ ? window._env_.REACT_APP_BROKER_NAME : false) ||
process.env.REACT_APP_BROKER_NAME;

const listsUrlBase =
    (window._env_ ? window._env_.REACT_APP_LISTS_API_URL : false) ||
    process.env.REACT_APP_LISTS_API_URL;

const listsUrlApi = `${listsUrlBase}listCollection/`;
const mailListQuotation = (window._env_ ? window._env_.REACT_APP_QUOTATION_MAIL_LIST: false) || process.env.REACT_APP_QUOTATION_MAIL_LIST
//liveness
const isTestEnvironment = (window._env_ ? window._env_.REACT_APP_IS_TEST_ENVIROMENT : false) ||
process.env.REACT_APP_IS_TEST_ENVIROMENT;

const validateResponse = (response) => {
    if (response.ok) {
        return response.json();
    } else {
        throw exeptionCodeResponse();
    }
};

const validateTextResponse = (response) => {
    if (response.ok) {
        return response.text();
    } else {
        throw exeptionCodeResponse();
    }
};

const exeptionCodeResponse = (error = "error") => {
    return error;
};

export default {
    recognitionAPPUrlBase,
    quotationUrlBase,
    quotationUrlApi,
    quotationUrlV2Api,
    siseUrlBase,
    siseUrlApi,
    validateResponse,
    validateTextResponse,
    vinculationFormID,
    authUrlBase,
    agentUrlApiBase,
    agentUrlApi,
    agentUrlPublicApi,
    siseBlacklist,
    siseReservado,
    siseGenerateLicenseNumber,
    isTestEnvironment,
    brokerName,
    listsUrlApi,
    mailListQuotation
};
